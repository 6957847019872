// App.js

import React, { useEffect } from 'react';
import { db } from './firebase'; // Import the Firebase database reference
import Component1 from './component1';
import Component2 from './component2';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  useEffect(() => {
    // Clear the data in Firebase on load
    const statusesRef = db.ref('statuses');
    statusesRef.remove()
      .then(() => {
        console.log('Statuses removed successfully.');
      })
      .catch(error => {
        console.error('Error removing statuses:', error);
      });
  }, []); // Empty dependency array ensures this runs only on mount

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Component1 />} />
        <Route path="/component2" element={<Component2 />} />
      </Routes>
    </Router>
  );
}

export default App;
