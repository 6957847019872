import React, { useState, useEffect } from 'react';
import firebase from './firebase.js'; // Adjust the path as per your project structure
import Btn from './button.png'

function Component1() {
  const [clickCount, setClickCount] = useState(0);
  const [numUsers] = useState(21); // Number of users
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const db = firebase.database();

  useEffect(() => {
    // Initialize statuses on component mount
    db.ref('statuses').once('value')
      .then(snapshot => {
        const statuses = snapshot.val() || Array(numUsers).fill(false);
        db.ref('statuses').set(statuses); // Ensure statuses are initialized in Firebase
      })
      .catch(error => console.error('Error fetching statuses:', error));
  }, [db]);

  const handleSetStatus = () => {
    if (!isButtonDisabled) {
      db.ref('statuses').once('value')
        .then(snapshot => {
          const statuses = snapshot.val() || Array(numUsers).fill(false);
          const currentIndex = statuses.findIndex(status => status === false);

          if (currentIndex >= 0 && currentIndex < numUsers) {
            statuses[currentIndex] = true;
            db.ref('statuses').set(statuses)
              .then(() => {
                setClickCount(prevCount => prevCount + 1);

                if (clickCount === numUsers - 1) {
                  // Show Reset button after 5 clicks
                  localStorage.setItem('showResetButton', 'true');
                }

                // Disable the button for 5 seconds
                setIsButtonDisabled(true);
                setTimeout(() => {
                  setIsButtonDisabled(false);
                }, 2000);
              })
              .catch(error => console.error('Error updating status:', error));
          }
        })
        .catch(error => console.error('Error fetching statuses:', error));
    }
  };

  const handleReset = () => {
    db.ref('statuses').set(Array(numUsers).fill(false))
      .then(() => {
        localStorage.removeItem('showResetButton');
        window.location.reload(); // Reload to reset Component2 images
      })
      .catch(error => console.error('Error resetting statuses:', error));
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-orange-400">
      <button 
        onClick={handleSetStatus} 
        className={`font-bold py-2 px-4 rounded ${isButtonDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
        disabled={isButtonDisabled}
      >
        <img src={Btn}/>
      </button>
      {localStorage.getItem('showResetButton') === 'true' && (
        <button 
          onClick={handleReset} 
          className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mt-4"
        >
          Reset
        </button>
      )}
    </div>
  );
}

export default Component1;
