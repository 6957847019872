import React, { useEffect, useState } from 'react';
import firebase from './firebase.js'; // Adjust the path as per your project structure
import Pic1 from './true.gif';
import Pic2 from './false.png';

function Component2() {
  const [statuses, setStatuses] = useState(Array(21).fill(false)); // Number of users
  const db = firebase.database();

  useEffect(() => {
    const statusesRef = db.ref('statuses');
    statusesRef.on('value', (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setStatuses(data);
      }
    });

    return () => {
      statusesRef.off(); // Clean up listener
    };
  }, [db]);

  return (
    <div className="bg-black min-h-screen flex flex-col items-center justify-center">
      <div className="grid grid-cols-1 md:grid-cols-7 gap-4">
        {statuses.map((status, index) => (
          <div key={index} className="p-4 rounded-lg">
            {status ? (
              <img src={Pic1} alt={`True for User ${index + 1}`} className="w-full" />
            ) : (
              <img src={Pic2} alt={`False for User ${index + 1}`} className="w-full" />
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Component2;
