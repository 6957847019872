// firebase.js

import firebase from 'firebase/compat/app';
import 'firebase/compat/database';

const firebaseConfig = {
  apiKey: "AIzaSyBrd1D43Z18lT-vZQriJY7NyrGjjcE0T58",
  authDomain: "lamp-36968.firebaseapp.com",
  projectId: "lamp-36968",
  storageBucket: "lamp-36968.appspot.com",
  messagingSenderId: "458772471817",
  appId: "1:458772471817:web:b9cbfb335ae79ccf3758d8",
  measurementId: "G-4P1CME2ZM7"
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
const db = app.database();

export { firebase, db };
export default firebase;
